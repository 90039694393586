import React from "react"

import SEO from "../components/seo"
import Layout from "../components/layout"
import ContactComponent from "../components/contactComponent"

import "../styles/pages-global.less"
import "../styles/layout.less"
import keys from "../images/house-key.png"

const About = () => (
  <>
    <SEO title="O nas" />
    <Layout>
      <section className="page-container">
        <div className="page-content container">
          <div className="row">
            <div className="col-lg-6">
              <h2 className="section-subtitle subpage-subtitle">
                Wieloletnie doświadczenie
              </h2>
              <p>
                Firma Master-Max powstała w 2010 roku i od początku swojej
                działalności głównym profilem jest produkcja Systemów Master
                Key. Posiadamy 15 letnie doświadczenie w dziedzinie
                projektowania systemów oraz budowie wkładek systemowych zdobyte
                przed powstaniem Firmy. Daje nam to ogólnie 25 lat pracy nad
                doskonaleniem technik przy składaniu wyrobów Master oraz
                kopiowaniu kluczy .
              </p>
              <h2 className="section-subtitle subpage-subtitle">
                Różnorodna oferta
              </h2>
              <p>
                FHU Master-Max dodatkowo zajmuje się profesjonalną naprawą
                obuwia polegająca na wymianie fleków, zelówek, dorabianiu
                pasków, szycie uszkodzeń, wszywanie zamków oraz woskowanie.
                Zajmujemy się również drobnymi naprawami kaletniczymi takimi jak
                przyszycia pasków, wymiana karabińczyków itp.{" "}
              </p>
              <p>
                W naszej firmie doradzimy ci również jaki preparat najlepszy
                będzie do twoich ulubionych butów i kupisz je u nas na miejscu
                .Preferujemy i polecamy pasty, impregnaty, tłuszcze tylko
                sprawdzonych firm a wkładki do butów , sznurowadła szczotki
                sprzedawanych przez nas są krajowych producentów. Dorobisz u nas
                także klucz mieszkaniowy zwykły i atestowany do wszystkich
                zamków, kłódek będących w sprzedaży.
              </p>
            </div>
            <div className="col-lg-6">
              <div className="photo-container shoes">
                <img className="photo-article" src={keys} alt="klucze" />
              </div>
              <ContactComponent />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <h2 className="section-subtitle subpage-subtitle">
                Profesjonalna obsługa
              </h2>
              <p>
                Zapraszamy do Naszej firmy od poniedziałku do piątku w godzinach
                10-18 a postaramy się abyś wyszedł od Nas zadowolony i w
                przyszłości wrócił. U Nas kupisz również - kłódkę, wkładkę do
                drzwi oraz zamówisz profesjonalne zabezpieczenie dla Twojego
                Domu lub Twojej Firmy. Jesteśmy Regionalnym Przedstawicielem
                Firmy LOB ASSA ABLOY - Tylko u Nas zamówisz System Kontroli
                Dostępu Master Key w cenach producenta.
              </p>
            </div>
            <div className="col-lg-6">
              
            </div>
          </div>
        </div>
      </section>
    </Layout>
  </>
)

export default About
