import React from "react"
import styled from "styled-components"
import "../styles/pages-global.less"

const contactData = [
  {
    label: "email",
    text: "info@master-max.pl",
  },
  {
    label: "phone",
    text: "731 506 950",
  },
]

const CallToAction = styled.div`
  font-size: 1.5rem;
  color: #949494;
  font-family: 'Signika';
  margin-left: 20px;

  @media screen and (min-width: 767px) {
      font-size: 2rem;
  }
`

const RedLine = styled.div`
  width: 20px;
  background-color: #b40100;
`

const CtaInvitation = styled.p`
    color: #b40100;
`

const ContactComponent = () => (
  <div className="cta-box">
    <RedLine />
    <CallToAction>
      <p>Masz pytania?</p>
      <p>Zadzwoń pod nr {contactData[1].text}</p>  
      <p>lub napisz {contactData[0].text}</p>
      <CtaInvitation>Zapraszamy!</CtaInvitation> 
    </CallToAction>
  </div>
)

export default ContactComponent
